.home-image-content {
    position: relative;
    width: 100%;
    height: inherit;
    max-width: 1366px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}

.home-image-box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50vw;
    flex: 0 0 50%;
    border: #eee solid 1px;
}
